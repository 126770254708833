import React from "react"
import { Helmet } from "react-helmet"
import LightLayout from "../layout/light-default"
import SectionHeader from "../components/organism/contact/header.organism"
import Contact from "../components/organism/contact/contact.organism"
import SectionMap from "../components/molecule/contact/section-map.molecule"
import SectionForm from "../components/molecule/contact/section-form.molecule"

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Glastone Mosaic</title>
      </Helmet>
      <LightLayout>
        <SectionHeader />
        <SectionForm dark={false} yesmarginTop />
        <SectionMap nomarginTop />
      </LightLayout>
    </>
  )
}
export default ContactPage
